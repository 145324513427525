import React from 'react';
import { FormControlLabel } from '@mui/material';
import { StyledRadio, Wrapper } from './radio.styled';
import { RadioProps } from './radio.type';

export const Radio: React.FC<RadioProps> = (props) => {
    return (
        <Wrapper>
            <FormControlLabel control={<StyledRadio {...props} />} label={props.label} />
        </Wrapper>
    );
};
