import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../theme/type/gap-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        !['direction', 'gap', 'alignItems', 'justifyContent', 'fullHeight', 'fullWidth'].includes(prop),
})<{
    direction: 'vertical' | 'horizontal';
    gap: GapSizeType;
    alignItems: 'flex-start' | 'center' | 'flex-end' | 'stretch';
    justifyContent: 'space-between' | 'space-around' | 'space-evenly' | 'flex-start' | 'flex-end' | 'center';
    fullHeight?: boolean;
    fullWidth?: boolean;
}>(
    ({ theme, direction, gap, alignItems, justifyContent, fullHeight, fullWidth }) => css`
        align-items: ${alignItems};
        display: inline-flex;
        flex-direction: ${direction === 'vertical' ? 'column' : 'row'};
        gap: ${theme.gap[gap]};
        justify-content: ${justifyContent};
        height: ${fullHeight ? '100%' : 'unset'};
        width: ${fullWidth ? '100%' : 'unset'};
    `,
);
