import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconWalletFilled: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <g>
            <path
                d="M18.87,18a3.76,3.76,0,0,1-3.67-4.54,3.82,3.82,0,0,1,3.78-3h2.39a.5.5,0,0,0,.5-.5c0-.79,0-2.25,0-2.25a2.51,2.51,0,0,0-2.5-2.5c-4.48,0,8.86,0-14,0a.75.75,0,1,1,0-1.5H18.62a.25.25,0,0,0,.25-.25V3.25c0-1.38-1.57-2.5-3.5-2.5h-11a3.5,3.5,0,0,0-3.5,3.5v15.5a3.5,3.5,0,0,0,3.5,3.5h15a2.5,2.5,0,0,0,2.5-2.5v-2.5a.25.25,0,0,0-.25-.25Z"
                fill="currentColor"
            />
            <path
                d="M21.87,12h-3a2.25,2.25,0,0,0,0,4.5h3A1.5,1.5,0,0,0,23.12,15V13.5A1.5,1.5,0,0,0,21.87,12Z"
                fill="currentColor"
            />
        </g>
    </Icon>
);
