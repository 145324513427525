import React from 'react';
import { Wrapper } from './stack.styled';
import { GapSizeType } from '../../../theme/type/gap-type';

interface StackProps {
    children: Array<React.ReactNode> | React.ReactNode;
    direction?: 'vertical' | 'horizontal';
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
    gap?: GapSizeType;
    justifyContent?: 'space-between' | 'space-around' | 'space-evenly' | 'flex-start' | 'flex-end' | 'center';
    fullHeight?: boolean;
    fullWidth?: boolean;
}

export const Stack: React.FC<StackProps> = ({
    children,
    direction = 'horizontal',
    gap = 'xsmall',
    alignItems = 'flex-start',
    justifyContent = 'center',
    fullHeight,
    fullWidth,
}) => {
    return (
        <Wrapper
            justifyContent={justifyContent}
            direction={direction}
            gap={gap}
            alignItems={alignItems}
            fullHeight={fullHeight}
            fullWidth={fullWidth}
        >
            {children}
        </Wrapper>
    );
};
