import styled, { DefaultTheme, css } from 'styled-components';
import { darken } from 'polished';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import { IconPortfolioSizeType, IconPortfolioVariantType } from '../../../../theme/type/icon-portfolio-type';

const getResponsiveIconWrapperSize = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        if (index === 0) {
            return `
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].max} {
                    width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};
                    min-width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};
                    height: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};

                    svg {
                        width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                        min-width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                        height: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                    }
                }

                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};
                    min-width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};
                    height: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};

                    svg {
                        width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                        min-width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                        height: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                    }
                }
            `;
        }
        return `
            @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};
                min-width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};
                height: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].wrapper};

                svg {
                    width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                    min-width: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                    height: ${theme.iconPortfolio.size[item[1] as IconPortfolioSizeType].icon};
                }
            }
        `;
    });
};

export const Wrapper = styled.i.withConfig({
    shouldForwardProp: (prop) => !['variant', 'size', 'dark'].includes(prop),
})<{
    variant: IconPortfolioVariantType | null;
    size: IconPortfolioSizeType | object;
    dark: boolean;
}>`
    ${({ theme, variant, size, dark }) => css`
        align-items: center;
        display: inline-flex;
        position: relative;
        transition: unset;
        justify-content: center;
        border-radius: 30rem;

        ${variant &&
        css`
            color: ${theme.iconPortfolio.variant[variant].base.color};
            background-color: ${theme.iconPortfolio.variant[variant].base.background};
            border: 0.1rem solid ${theme.iconPortfolio.variant[variant].base.border};

            ${dark &&
            css`
                background-color: ${darken(0.1, theme.iconPortfolio.variant[variant].base.background)};
            `}
        `}

        img {
            height: auto !important;
        }

        svg {
            align-items: center;
            display: inline-flex;
            height: inherit;
            stroke-width: 0;
            transition: unset;
            width: inherit;
            fill: transparent;
            margin: unset;
        }

        ${typeof size === 'string' &&
        css`
            width: ${theme.iconPortfolio.size[size].wrapper};
            min-width: ${theme.iconPortfolio.size[size].wrapper};
            height: ${theme.iconPortfolio.size[size].wrapper};

            svg {
                width: ${theme.iconPortfolio.size[size].icon};
                min-width: ${theme.iconPortfolio.size[size].icon};
                height: ${theme.iconPortfolio.size[size].icon};
            }
        `}

        ${typeof size === 'object' &&
        css`
            ${getResponsiveIconWrapperSize(size, theme)};
        `}
    `}
`;
