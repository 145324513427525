import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconArrowDown: React.FC = () => (
    <Icon width="11" height="7" viewBox="0 0 11 7" fill="none">
        <g clipPath="url(#clip0_1_2)">
            <path
                d="M1 1L5.5 5.5L10 1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_2">
                <rect width="11" height="6.5" fill="white" />
            </clipPath>
        </defs>
    </Icon>
);
