import * as React from 'react';
import { ChangeEventHandler, useMemo } from 'react';

import { Wrapper, IconWrapper, ToggleSwitch, Label } from './switch.styled';
import { GapSizeType } from '../../../../theme/type/gap-type';
import { SwitchType } from '../../../../theme/type/switch-type';

interface SwitchPropsInterface {
    name?: string;
    iconOff?: React.ReactNode;
    iconOn?: React.ReactNode;
    disabled?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    checked?: boolean;
    label?: React.ReactNode;
    marginBottom?: GapSizeType;
    marginTop?: GapSizeType;
    variant?: SwitchType;
}

export const Switch: React.FC<SwitchPropsInterface> = ({
    name,
    disabled,
    onChange,
    iconOn,
    iconOff,
    checked = false,
    label,
    marginBottom,
    marginTop,
    variant = 'primary',
}: SwitchPropsInterface) => {
    const icon = useMemo(() => {
        if (iconOn && iconOff) {
            return checked ? iconOn : iconOff;
        }
        return null;
    }, [iconOn, iconOff]);

    return (
        <Wrapper marginBottom={marginBottom} marginTop={marginTop}>
            <ToggleSwitch variant={variant} isActive={checked}>
                <input
                    name={name}
                    id={name}
                    type="checkbox"
                    onChange={(e) => onChange(e)}
                    disabled={disabled}
                    checked={checked}
                />
                <IconWrapper isActive={checked} variant={variant}>
                    {icon}
                </IconWrapper>
            </ToggleSwitch>
            {label && <Label htmlFor={name}>{label}</Label>}
        </Wrapper>
    );
};
