import React, { useMemo } from 'react';
import { IconNameEnum } from '@app/common';
import { IconChartBarFilled } from '../../icons/chart-bar-filled';
import { IconDividendsFilled } from '../../icons/dividends-filled';
import { IconTradesFilled } from '../../icons/trades-filled';
import { IconWalletFilled } from '../../icons/wallet-filled';
import { IconProfitsFilled } from '../../icons/profits-filled';
import { Wrapper } from './icon-portfolio.styled';
import { IconPortfolioSizeType, IconPortfolioVariantType } from '../../../../theme/type/icon-portfolio-type';
import { IconHeartFilled } from '../../icons/heart-filled';

export interface PortfolioIconPropsInterface {
    type: IconNameEnum | null;
    variant: IconPortfolioVariantType | null;
    size?: IconPortfolioSizeType | object;
    dark?: boolean;
}

export const IconPortfolio: React.FC<PortfolioIconPropsInterface> = ({
    type,
    variant,
    size = 'medium',
    dark = false,
}) => {
    const portfolioIcon = useMemo(() => {
        if (type === 'ChartBarFilled') {
            return <IconChartBarFilled />;
        }
        if (type === 'HeartFilled') {
            return <IconHeartFilled />;
        }
        if (type === 'DividendsFilled') {
            return <IconDividendsFilled />;
        }
        if (type === 'ProfitsFilled') {
            return <IconProfitsFilled />;
        }
        if (type === 'TradesFilled') {
            return <IconTradesFilled />;
        }
        if (type === 'WalletFilled') {
            return <IconWalletFilled />;
        }
        return null;
    }, [type]);

    return (
        <Wrapper variant={variant} size={size} dark={dark}>
            {portfolioIcon}
        </Wrapper>
    );
};
