import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../../theme/type/gap-type';
import { SwitchType } from '../../../../theme/type/switch-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['marginBottom', 'marginTop'].includes(prop),
})<{
    marginBottom?: GapSizeType;
    marginTop?: GapSizeType;
}>`
    ${({ theme, marginBottom, marginTop }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.common.text};
        font-weight: 500;

        ${marginBottom &&
        css`
            margin-bottom: ${theme.gap[marginBottom]};
        `}

        ${marginTop &&
        css`
            margin-top: ${theme.gap[marginTop]};
        `}
    `}
`;

export const ToggleSwitch = styled.label.withConfig({
    shouldForwardProp: (prop) => !['isActive', 'variant'].includes(prop),
})<{
    isActive?: boolean;
    variant: SwitchType;
}>`
    ${({ theme, isActive, variant }) => css`
        background: ${theme.switchCheckbox[variant].background.off};
        border-radius: 1.2rem;
        height: 2.4rem;
        display: flex;
        cursor: pointer;
        position: relative;
        width: 4rem;

        ${isActive &&
        css`
            background: ${theme.switchCheckbox[variant].background.on};
        `}

        input {
            display: none;
        }
    `}
`;

export const IconWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive', 'variant'].includes(prop),
})<{
    isActive?: boolean;
    variant: SwitchType;
}>`
    ${({ theme, isActive, variant }) => css`
        background-color: ${theme.palette.color.white.main};
        width: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.6rem;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        transform: translate(0.4rem, -50%);
        transition: transform ${theme.base.transition};

        i {
            color: ${theme.switchCheckbox[variant].icon.off};
            width: 1rem;
            height: 1rem;
            min-width: 1rem;
        }

        ${isActive &&
        css`
            transform: translate(2rem, -50%);

            i {
                color: ${theme.switchCheckbox[variant].icon.on};
            }
        `}
    `}
`;

export const Label = styled.label`
    margin-left: 1rem;

    &[for] {
        cursor: pointer;
    }
`;
