import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconHeartFilled: React.FC = () => (
    <Icon viewBox="0 0 16 14" fill="none">
        <path
            d="M11.685 1.5024e-06C11.1169 -0.000470421 10.5544 0.11024 10.0305 0.325643C9.50665 0.541045 9.03191 0.856803 8.63416 1.2544L8 1.8816L7.36584 1.2544C6.9676 0.85752 6.49275 0.542311 5.96896 0.327148C5.44517 0.111986 4.88292 0.00117456 4.315 0.00117456C3.74707 0.00117456 3.18483 0.111986 2.66104 0.327148C2.13725 0.542311 1.6624 0.85752 1.26416 1.2544C0.45396 2.06283 0 3.15076 0 4.284C0 5.41724 0.45396 6.50517 1.26416 7.3136L8 14L14.7358 7.3136C15.546 6.50517 16 5.41724 16 4.284C16 3.15076 15.546 2.06283 14.7358 1.2544C14.3377 0.857308 13.8629 0.541882 13.3391 0.326516C12.8153 0.11115 12.253 0.000154615 11.685 1.5024e-06Z"
            fill="currentColor"
        />
    </Icon>
);
