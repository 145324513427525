import { Radio } from '@mui/material';
import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: inline;

        .MuiTypography-root {
            font-weight: ${theme.base.fontWeight};
            font-family: ${theme.base.fontFace};
            font-size: ${theme.text.size.medium.fontSize};
            position: relative;
            top: -0.1rem;
        }

        .Mui-checked + .MuiTypography-root {
            color: ${theme.palette.color.secondary.main};
            font-weight: 700;
        }
    `}
`;

export const StyledRadio = styled(({ ...props }) => <Radio {...props} />)`
    ${({ theme }) => css`
        .MuiSvgIcon-root {
            color: ${theme.palette.color.secondary.main};
            font-weight: ${theme.base.fontWeight};
            font-family: ${theme.base.fontFace};
            font-size: ${theme.text.size.medium.fontSize};
            width: 2.4rem;
            height: 2.4rem;
        }
    `}
`;
