import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconTradesFilled: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            d="M23,6.39H20.94a2.06,2.06,0,0,0-.9.21.24.24,0,0,1-.2,0L15.54,5h0A3.34,3.34,0,0,0,13,5.11h0l-2.12,1a.29.29,0,0,1-.24,0A3,3,0,0,0,8,5.93H8L5,7.21a.26.26,0,0,1-.29-.07,2.12,2.12,0,0,0-1.62-.75H1a.76.76,0,0,0-.75.75v9.5a.75.75,0,0,0,.75.75H3.06a2.1,2.1,0,0,0,1.73-.89A.26.26,0,0,1,5,16.39h.93a.22.22,0,0,1,.15.06l2.8,2.19a1.9,1.9,0,0,0,1.38.59,2,2,0,0,0,1.24-.44l3.05-2.51a.22.22,0,0,1,.11-.05l3.9-.78a.22.22,0,0,1,.19,0,.25.25,0,0,1,.1.16,2.12,2.12,0,0,0,2.09,1.74H23a.75.75,0,0,0,.75-.75V7.14A.76.76,0,0,0,23,6.39Zm-8.22,7.12-4.32,3.61a.23.23,0,0,1-.28,0L7,14.61a1,1,0,0,0-.62-.22H5.44a.25.25,0,0,1-.25-.25V9.45a.25.25,0,0,1,.15-.23L8.72,7.78h0a.24.24,0,0,1,.24,0A.22.22,0,0,1,9,8.05a2.73,2.73,0,0,0,.1,1.64,2.68,2.68,0,0,0,3.46,1.49h0l.16-.07a.26.26,0,0,1,.28.06l1.79,2a.23.23,0,0,1,.06.18A.23.23,0,0,1,14.78,13.51Zm4-.36a.25.25,0,0,1-.2.25l-1.31.26a.23.23,0,0,1-.21-.05.27.27,0,0,1-.09-.2c0-1.05-.35-1.21-3.09-4.23a1.06,1.06,0,0,0-1.12-.25h0l-1,.39h0a.67.67,0,0,1-.5,0A.68.68,0,0,1,11,9a.65.65,0,0,1,.31-.82h0L13.85,6.9h0a1.32,1.32,0,0,1,1-.06h0l3.84,1.47a.21.21,0,0,1,.13.19Z"
            fill="currentColor"
        />
    </Icon>
);
