import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconProfitsFilled: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <g>
            <path
                d="M17.7,11.21a.25.25,0,0,0-.18-.08.27.27,0,0,0-.19.08,2.31,2.31,0,0,1-1.83.54h-4a5.06,5.06,0,0,1-1-.1.26.26,0,0,0-.25.08A11,11,0,0,0,7.5,18.49c0,3.51,3.6,4.76,6.68,4.76,3.4,0,6.82-1.47,6.82-4.76C21,15.39,19,12.61,17.7,11.21Zm-3.62,6.54c-.94-.64-1.83-1.24-1.83-2.12a1.34,1.34,0,0,1,1.27-1.49.26.26,0,0,0,.23-.25v-.14a.75.75,0,0,1,1.5,0v.13a.25.25,0,0,0,.25.25H16a.75.75,0,0,1,0,1.5H14.45a.25.25,0,0,0-.24.17.24.24,0,0,0,.09.28l.62.42c.94.64,1.83,1.24,1.83,2.13a1.38,1.38,0,0,1-1.33,1.49.18.18,0,0,0-.17.18v.2a.75.75,0,0,1-1.5,0v-.12a.25.25,0,0,0-.25-.25H13a.75.75,0,1,1,0-1.5h1.54a.25.25,0,0,0,.24-.18.26.26,0,0,0-.09-.28C14.49,18,14.26,17.87,14.08,17.75Z"
                fill="currentColor"
            />
            <path
                d="M16.57,3.75a.25.25,0,0,0,.23-.12L18.06,1.5a.5.5,0,0,0,0-.5.52.52,0,0,0-.44-.25H11.38a.52.52,0,0,0-.44.25.54.54,0,0,0,0,.51l1.39,2.13a.26.26,0,0,0,.21.11Z"
                fill="currentColor"
            />
            <path
                d="M11.5,10.75h4c1,0,1.18-.05,1.5-1,.5-1.5,1-4,1-4,0-.5-.5-1-1.5-1H12a.26.26,0,0,1-.21-.11l-1-1.58a.24.24,0,0,0-.2-.12.25.25,0,0,0-.21.11L9.5,4.25h0a1.34,1.34,0,0,1-1,.5H7.75A.25.25,0,0,0,7.5,5V9.1a.24.24,0,0,0,.14.22C9.51,10.26,10.35,10.75,11.5,10.75Z"
                fill="currentColor"
            />
            <path
                d="M5.5,4.25H3.25A.25.25,0,0,0,3,4.5V10a.25.25,0,0,0,.25.25H5.5a.47.47,0,0,0,.5-.5v-5A.47.47,0,0,0,5.5,4.25Z"
                fill="currentColor"
            />
        </g>
    </Icon>
);
